<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item>
        <el-input
          v-model.trim="formInline.text"
          placeholder="请输入协议code/协议名称"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="clearData()"> 重置 </el-button>
        <el-button
          v-if="$checkPermission('ADDPROTOCOL', $route)"
          type="primary"
          @click="dataOperation(null, 2)"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="code" label="协议code" align="center" />
      <el-table-column prop="title" label="协议名称" align="center" />
      <el-table-column prop="updateBy" label="更新人" align="center" />
      <el-table-column prop="updateTime" label="更新时间" align="center" />
      <el-table-column prop="status" label="是否启用" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#A9A9A9"
            @change="
              (val) => {
                scope.row.status = val;
                onchange(scope.row);
              }
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            查看
          </el-button>
          <el-button
            v-if="$checkPermission('EDITPROTOCOL', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceManageList',
  data() {
    return {
      titleType: '',
      loading: false, // 给列表加上loading效果
      formInline: {
        text: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {},
  mounted() {
    this.getProtocolList();
  },
  methods: {
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getProtocolList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getProtocolList();
    },
    // 重置
    clearData() {
      this.formInline = {
        // 查询条件
        text: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getProtocolList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getProtocolList();
    },
    // 分页查询用户套餐列表
    getProtocolList() {
      this.loading = true;
      const params = {
        ...this.formInline,
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.$api
        .agreementPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      this.$router.push({
        path: '/privacyPolicy/protocolDetail',
        query: {
          type: type,
          id: item !== null ? item.id : '',
        },
      });
    },
    onchange(row) {
      this.$api.agreementChangeStatus({ id: row.id }).then((res) => {
        if (res.code === 0) {
          this.$message.success({
            message: '状态变更成功',
            duration: 500,
          });
          this.getProtocolList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}
</style>
